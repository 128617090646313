import { Flex, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { SocialMedia } from '../SocialMedia/SocialMedia';
import { Section } from '../common/Section/Section';

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          title
        }
      }
    }
  `);
  return (
    <Section
      backgroundColor="gray.800"
      color="gray.200"
      py="32px"
      flexDir="row"
      justifyContent="space-between"
    >
      <Text>
        © {data.site.siteMetadata.title}, {new Date().getFullYear()}
      </Text>
      <SocialMedia />
    </Section>
  );
};
