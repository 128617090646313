import { Box, Heading, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { Card } from '../../common/Card/Card';

export const AboutCard = () => {
  const heroImg = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 196) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Card position="relative" px={{ base: 4, md: 6, xl: 8 }} mt="64px" pb="64px">
      <Box
        alignSelf="center"
        w="196px"
        rounded="full"
        objectFit="cover"
        boxSize="196px"
        border="5px solid"
        borderColor="gray.800"
        backgroundColor="gray.50"
        overflow="hidden"
      >
        <GatsbyImage
          fluid={heroImg.img.childImageSharp.fluid}
          style={{ height: '100%', width: 'auto' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </Box>

      <Heading
        as="h2"
        size="lg"
        color="gray.200"
        mt="24px"
        mb={2}
        alignSelf="center"
        borderBottom="3px solid"
        borderBottomColor="primary.500"
        pb={2}
        px={6}
      >
        Leszek Zając
      </Heading>
      <Heading as="h2" size="sm" color="gray.300" fontWeight="700" mb="32px" alignSelf="center">
        Fizjoterapeuta, osteopata
      </Heading>

      <Text maxW="35rem" color="gray.200" fontSize="14px" lineHeight={2} textAlign="justify">
        Osteopatia jest specjalizacją medyczną, która traktuje pacjenta jako całość. Łącząc
        dolegliwości fizyczne, psychiczne i duchowe terapeuta jest w stanie poprawić aktualny stan
        zdrowia pacjenta, oraz wskazać mu odpowiedni kierunek w celu zapobiegania kolejnym
        kontuzjom. Podczas mojej praktyki najważniejsze jest indywidualne dobranie metod leczenia,
        holistyczne podejście do pacjenta oraz współpraca interdysyplinarna zawodów medycznych.
        Dzięki temu można otoczyć pacjenta właściwą opieką.
      </Text>
    </Card>
  );
};
