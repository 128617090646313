import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import { Button } from '../../common/Button/Button';
import { Card } from '../../common/Card/Card';
import { Image } from '../../common/Image/Image';
import { SubHeader } from '../../common/Typography/Heading/Heading';
import { Price } from '../Price/Price';
import {
  CooperationDetails,
  CooperationDetailsProps,
} from './CooperationDetails/CooperationDetails';

export declare namespace Cooperations {
  export type Action = {
    label: string;
    target: string;
  };
  export type Props = {
    cooperations: {
      title: string;
      subtitle: string;
      image: string;
      action?: Action;
      details: CooperationDetailsProps[];
    }[];
  };
}

const getButton = ({ label, target }: Cooperations.Action) => {
  return (
    <a href={target} target="_blank" rel="nofollow">
      <Button>{label}</Button>
    </a>
  );
};

export const Cooperations = (props: Cooperations.Props) => {
  const { cooperations } = props;
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', xl: 'repeat(auto-fit, 626px)' }}
      gridColumnGap="2px"
      justifyContent="space-around"
    >
      {cooperations.map(({ image, title, subtitle, details, action }) => (
        <Card
          key={title}
          mb="24px"
          pt="16px"
          flexDir={{ base: 'column', sm: 'row', xl: 'column' }}
          px={{ base: '24px', sm: '48px', xl: '24px' }}
        >
          <Flex justifyContent="space-between" alignItems="baseline" pb={8}>
            <Text fontSize="14px" color="gray.200" alignItems="baseline">
              Zapraszam na wizytę do gabinetu
            </Text>
            <Price />
          </Flex>

          <Flex
            justifyContent={{ base: 'center', sm: 'flex-start', xl: 'center' }}
            mb={{ base: '24px', sm: '0', xl: '24px' }}
            mr={{ base: '0', sm: '32px', xl: '0' }}
          >
            <Image backgroundColor="gray.100" src={image} />
          </Flex>

          <Flex flexDir="column" flexGrow={1} justifyContent="space-between">
            <Box>
              <Flex
                alignItems={{ base: 'center', sm: 'flex-start', xl: 'center' }}
                flexDirection="column"
                pb={4}
              >
                <SubHeader color="gray.200" mb="10px" textAlign="center">
                  {title}
                </SubHeader>

                <SubHeader
                  as="h4"
                  color="gray.200"
                  borderBottom="none"
                  fontSize="17px"
                  fontWeight={500}
                >
                  {subtitle}
                </SubHeader>
              </Flex>

              <Box>
                {details.map((item) => (
                  <CooperationDetails key={item.id} {...item} />
                ))}
              </Box>
            </Box>

            <Flex justifyContent={{ base: 'center', sm: 'flex-end', xl: 'center' }} mt="24px">
              {action && getButton(action)}
            </Flex>
          </Flex>
        </Card>
      ))}
    </Grid>
  );
};
