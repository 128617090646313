import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

export const Button: FunctionComponent<ButtonProps> = (props) => (
  <ChakraButton
    rounded={0}
    color="gray.50"
    bg="primary.600"
    fontWeight="600"
    _hover={{ bg: 'primary.500' }}
    {...props}
  />
);
