import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Section } from '../common/Section/Section';
import { SectionHeader } from '../common/Typography/SectionHeder/SectionHeader';
import { AboutCard } from './AboutCard/AboutCard';

export const About = () => (
  <Section striped px={0} pb="128px">
    <Flex justifyContent="center">
      <AboutCard />
    </Flex>
  </Section>
);
