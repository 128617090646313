import { Box, Flex, Heading, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'
import React, { FunctionComponent } from 'react';
import * as reactIcons from 'react-icons/all';
import { Icon } from '../../common/Icon/Icon';

export type SingleIndicationProps = {
  name: string;
  subtitle?: string;
  id: string;
  icon: string;
  reasons?: {
    name: string;
    id: string;
  }[];
};

export const SingleIndication: FunctionComponent<SingleIndicationProps> = ({
  name,
  subtitle,
  reasons,
  icon,
}) => {
  // @ts-ignore
  const IconComponent = reactIcons[icon as string];
  return (
    <Flex position="relative" mx="8px" flexGrow={1}>
      {icon && (
        <Box fontSize="150px" position="absolute" zIndex={0} top="128px" right={0}>
          <Icon IconComponent={IconComponent} stroke="gray.200" fill="gray.200" />
        </Box>
      )}
      <Box zIndex={10}>
        <Heading as="h3" size="md" mb="24px" color="gray.600">
          {name}
        </Heading>
        {subtitle && <Text lineHeight="1.8">{subtitle}</Text>}
        {Array.isArray(reasons) && (
          <List>
            {reasons.map((item) => (
              <ListItem mb="12px" key={item.id}>
                <ListIcon as={CheckIcon} color="primary.500" />
                <Text display="inline" ml="0px" color="gray.800">
                  {item.name}
                </Text>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Flex>
  );
};
