import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const Card = (props: FlexProps) => (
  <Flex
    flexDir={{ base: 'column' }}
    borderWidth="1px"
    borderColor="gray.200"
    py="24px"
    px="48px"
    backgroundColor="gray.800"
    boxShadow="sm"
    {...props}
  />
);
