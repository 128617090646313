import React from 'react';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';
import { About } from '../components/About/About';
import { Footer } from '../components/Footer/Footer';
import { Hero } from '../components/Hero/Hero';
import { Indications } from '../components/Indications/Indications';
import Layout from '../components/Layout/Layout';
import { Offer } from '../components/Offer/Offer';
import SEO from '../components/Seo/Seo';

const Index = () => (
  <Layout>
    <SEO title="Leszek Zając" lang="pl" />
    <Element name="hero">
      <Hero />
    </Element>
    <Element name="offer">
      <Offer />
    </Element>
    <Element name="indications">
      <Indications />
    </Element>
    <Element name="about">
      <About />
    </Element>
    <Footer />
  </Layout>
);

export default Index;
