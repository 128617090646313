import { Flex, Grid, Heading } from '@chakra-ui/react';
import React from 'react';
import { Section } from '../common/Section/Section';
import { SectionHeader } from '../common/Typography/SectionHeder/SectionHeader';
import { SingleIndication, SingleIndicationProps } from './SingleIndication/SingleIndication';

const mockData: SingleIndicationProps[] = [
  {
    name: 'Przewlekłe dolegliwości bólowe',
    id: 'a66bf3ef-448a-4632-8757-5323a93d4e1f',
    icon: 'AiFillFire',
    reasons: [
      {
        id: '76d7dfce-f536-4ebb-a17f-a4439b08e810',
        name: 'Bóle głowy',
      },
      {
        id: '683f7856-a047-4cec-9f89-2fa714c75e24',
        name: 'Bóle kręgosłupa',
      },
      {
        id: '24856667-e3de-4568-8722-f08f53420f83',
        name: 'Bóle stawów',
      },
      {
        id: '99ff2633-53e3-4e13-880d-7eb873707b9f',
        name: 'Nerwobóle',
      },
      {
        id: '89ff2633-53e3-4e13-880d-7eb873707b9f',
        name: 'Bóle brzucha',
      },
      {
        id: '79ff2633-53e3-4e13-880d-7eb873707b9f',
        name: 'Bolesne miesiączki',
      },
      {
        id: '69ff2633-53e3-4e13-880d-7eb873707b9f',
        name: 'Dolegliwości bólowe na podłożu stresowym',
      },
    ],
  },
  {
    name: 'Fizjoterapia ortopedyczna',
    id: 'e57a6613-7579-4fc3-9fa2-bdafeafbad60',
    icon: 'BiPlusMedical',
    reasons: [
      {
        id: '0931a562-038b-4f47-8d2d-fdc293fe8fb3',
        name: 'Zwichnięcia i skręcenie stawów',
      },
      {
        id: 'a97fcb9b-d87d-4cb0-8f4f-a3282dae17e3',
        name: 'Złamania',
      },
      {
        id: '7221e10b-1da8-4cd1-beb6-2ab591948e82',
        name: 'Przewlekłe i ostre stany zapalne tkanek miękkich',
      },
      {
        id: '2d8eb02e-9b70-4858-abce-f0bd043fee29',
        name: 'Rwa kulszowa, rwa barkowa',
      },
      {
        id: '7641fbe5-0e5a-4aa9-b314-1288eea8433d',
        name: 'Fizjoterapia przed- i pooperacyjna',
      },
      {
        id: '3641fbe5-0e5a-4aa9-b314-1288eea8433d',
        name: 'Bark zamrożony',
      },
      {
        id: '4641fbe5-0e5a-4aa9-b314-1288eea8433d',
        name: 'Łokieć golfisty, łokieć tenisisty',
      },
    ],
  },
  {
    name: 'Fizjoterapia stomatologiczna',
    id: '32abe616-c057-4a54-ac78-965c0041cfdc',
    icon: 'FaTooth',
    reasons: [
      {
        id: '2f8feb41-5cda-4de2-8292-6ddb95f4ea5e',
        name: 'Bóle lub ograniczenie ruchu żuchwy',
      },
      {
        id: '6da4f39e-07bb-4e2f-b8e4-a83498d7c27f',
        name: 'Zgrzytanie zębami lub zaciskanie zębów',
      },
      {
        id: '13902731-b91e-4e70-9f05-c7bf3b1f22ec',
        name: 'Bóle zdrowych zębów, drętwienie lub ból twarzy',
      },
      {
        id: '12902731-b91e-4e70-9f05-c7bf3b1f22ec',
        name: 'Trzaski, przeskakiwanie lub niestabilność przy otwieraniu/zamykaniu ust',
      },
      {
        id: '11902731-b91e-4e70-9f05-c7bf3b1f22ec',
        name: 'Nawracające zapalenie lub ból zatok',
      },
      {
        id: '10902731-b91e-4e70-9f05-c7bf3b1f22ec',
        name: 'Szumy uszne',
      },
      {
        id: '09902731-b91e-4e70-9f05-c7bf3b1f22ec',
        name: 'Problemy z przełykaniem, żuciem, mówieniem, ziewaniem',
      },
    ],
  },
  {
    name: 'Zaburzenia pracy układu pokarmowego',
    id: '74c2cf06-cfcf-436d-9c60-1a0fee01439f',
    icon: 'GiStomach',
    reasons: [
      {
        id: '18d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Zaburzenia pracy jelit',
      },
      {
        id: '28d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Biegunki, zaparcia',
      },
      {
        id: '38d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Zespół jelita drażliwego',
      },
      {
        id: '48d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Refluks żołądkowo - przełykowy',
      },
      {
        id: '58d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Zgagi',
      },
      {
        id: '68d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Bóle brzucha',
      },
      {
        id: '78d2668c-69cf-4a17-9fc2-05376827fd1a',
        name: 'Zaburzenia połykania, bóle gardła',
      },
    ],
  },
  {
    name: 'Zaburzenia pracy układu krwionośnego i limfatycznego',
    id: '806d0081-5d43-499b-bc6c-dc527f53abdb',
    icon: 'GrFavorite',
    reasons: [
      {
        id: '073cca13-d73d-4aeb-95ec-36e142bcfe8b',
        name: 'Obrzęki kończyn',
      },
      {
        id: '0bfb84e0-6179-46ea-bb2e-209c1b29e74d',
        name: 'Drętwienie rąk lub nóg',
      },
      {
        id: 'a9f9cff8-3bb9-4efc-8e1f-74a7de5713c0',
        name: 'Zastoje żylne',
      },
      {
        id: '1c059a29-c0f4-4a1d-a7a0-01684ac1a099',
        name: 'Uczucie duszności i ciężkiego oddechu',
      },
      {
        id: '1c059a29-c0f4-4a1d-a7a0-01684ac1a0981',
        name: 'Nawracające infekcje',
      },
      {
        id: '1c059a29-c0f4-4a1d-a7a0-01684ac1a092',
        name: 'Zaburzenia oddychania',
      },
      {
        id: '1c059a29-c0f4-4a1d-a7a0-01684ac1a093',
        name: 'Zawroty głowy',
      },
    ],
  },
  {
    name: 'Integracja osteopatyczna',
    subtitle:
      'Niektóre z podanych objawów mogą występować wspólnie i wpływać na rozwój dolegliwości. Terapia osteopatyczna przez całościowe podejście ma na celu zniwelowanie wszystkich czynników mogących prowokować objawy z którymi zgłosi się pacjent na wizytę.',
    id: '806d0081-5d43-499b-bc6c-dc527f53aasd',
    icon: 'MdSync',
  },
];

export const Indications = () => (
  <Section flexDir="column">
    <SectionHeader>Kiedy umówić się na terapię</SectionHeader>

    <Grid
      gridTemplateColumns={{
        base: '1fr',
        sm: '1fr 1fr',
        md: '1fr 1fr 1fr',
        lg: '1fr 1fr',
        xl: '1fr 1fr 1fr',
      }}
      gridColumnGap="12px"
      gridRowGap="64px"
    >
      {mockData.map((data) => (
        <SingleIndication key={data.id} {...data} />
      ))}
    </Grid>
  </Section>
);
