import { useTheme, Link, Text } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { GrLink, GrMailOption, GrMap, GrPhone, GrFacebookOption, GrInstagram, GrClock } from 'react-icons/gr';
import { IconType } from 'react-icons/lib';
import { Icon } from '../../../common/Icon/Icon';
import { TextWrapper } from './CooperationDetails.style';

export type CooperationDetailsProps = {
  id: string;
  value: string;
  href?: string;
};

export const typeToIconMap = ({
  site: GrLink,
  fb: GrFacebookOption,
  ig: GrInstagram,
  phone: GrPhone,
  email: GrMailOption,
  address: GrMap,
  open: GrClock
} as unknown) as { [key: string]: IconType };

export const CooperationDetails: FunctionComponent<CooperationDetailsProps> = ({
  id,
  value,
  href,
}) => {
  const content = (
    <TextWrapper>
      <Text mr={3} fontSize="22px">
        <Icon stroke="gray.500" fill={id === 'fb' || id === 'ig' ? "gray.500" : ''} IconComponent={typeToIconMap[id]} />
      </Text>
      {value}
    </TextWrapper>
  );

  return href ? (
    <Link href={href} isExternal>
      {content}
    </Link>
  ) : (
    content
  );
};
