import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export type SectionProps = FlexProps & {
  striped?: boolean;
};

export const Section = (props: SectionProps) => (
  <Flex
    bg={props.striped ? 'gray.100' : ''}
    as="section"
    py="64px"
    px={{ base: 4, md: 6, xl: 8 }}
    flexDir={{ base: 'column' }}
    {...props}
  />
);
