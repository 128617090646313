import { Box, Heading, HeadingProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

export const SubHeader: FunctionComponent<HeadingProps> = (props) => (
  <Heading
    as="h2"
    size="md"
    color="gray.500"
    fontWeight="700"
    mb={4}
    borderBottom="3px solid"
    borderBottomColor="primary.500"
    pb={2}px={6}
    {...props}
  />
);
