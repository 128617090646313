import { Flex, Heading, HeadingProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

export const SectionHeader: FunctionComponent<HeadingProps> = (props) => (
  <Flex justifyContent="flex-start">
    <Heading
      as="h3"
      size="lg"
      color="gray.600"
      borderBottom="3px solid"
      borderBottomColor="primary.500"
      fontWeight={500}
      pb="16px"
      mb="48px"
      {...props}
    />
  </Flex>
);
