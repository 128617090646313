import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Link } from 'react-scroll';
// @ts-ignore
import hero from '../../images/hero.jpg';
import { Button } from '../common/Button/Button';
import { Section } from '../common/Section/Section';
import { SubHeader } from '../common/Typography/Heading/Heading';

export const Hero = () => {
  const heroImg = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box
      as={Section}
      position="relative"
      justifyContent="space-between"
      alignItems="center"
      flexDir={{ base: 'column-reverse', md: 'row-reverse' }}
      paddingTop={{ base: '32px', md: '64px' }}
      paddingBottom={{ base: '128px', md: '0' }}
      _after={{
        content: '""',
        backgroundColor: 'gray.50',
        backgroundImage:
          'url("data:image/svg+xml,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20858.95%20842.98%22%3E%20%20%20%20%20%3Cg%20id%3D%22Warstwa_2%22%20data-name%3D%22Warstwa%202%22%3E%20%20%20%20%20%20%20%20%20%3Cg%20id%3D%22Warstwa_1-2%22%20data-name%3D%22Warstwa%201%22%3E%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%2308525a%22%20fill-opacity%3D%220.03%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20d%3D%22M573.77%2C280.15a49.46%2C49.46%2C0%2C0%2C0-30.84%2C10.76l-63.86-31.19c0-.12%2C0-.24%2C0-.36a49.75%2C49.75%2C0%2C0%2C0-99.5%2C0c0%2C.12%2C0%2C.24%2C0%2C.36l-63.86%2C31.19a49.72%2C49.72%2C0%2C1%2C0%2C18.9%2C39c0-.12%2C0-.24%2C0-.37l63.86-31.18a49.59%2C49.59%2C0%2C0%2C0%2C61.69%2C0L524%2C329.53c0%2C.13%2C0%2C.25%2C0%2C.37a49.76%2C49.76%2C0%2C1%2C0%2C49.75-49.75Z%22%2F%3E%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%2308525a%22%20fill-opacity%3D%220.03%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20d%3D%22M573.77%2C406.6a49.46%2C49.46%2C0%2C0%2C0-30.84%2C10.76l-63.86-31.18c0-.13%2C0-.25%2C0-.37a49.75%2C49.75%2C0%2C0%2C0-99.5%2C0c0%2C.12%2C0%2C.24%2C0%2C.37l-63.86%2C31.18a49.72%2C49.72%2C0%2C1%2C0%2C18.9%2C39c0-.12%2C0-.24%2C0-.36l63.86-31.19a49.59%2C49.59%2C0%2C0%2C0%2C61.69%2C0L524%2C456c0%2C.12%2C0%2C.24%2C0%2C.36a49.76%2C49.76%2C0%2C1%2C0%2C49.75-49.75Z%22%2F%3E%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%2308525a%22%20fill-opacity%3D%220.03%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20d%3D%22M573.77%2C632.55a49.75%2C49.75%2C0%2C1%2C0-30.84-88.74l-63.86-31.18c0-.12%2C0-.24%2C0-.37a49.75%2C49.75%2C0%2C0%2C0-99.5%2C0c0%2C.13%2C0%2C.25%2C0%2C.37l-63.86%2C31.18a49.72%2C49.72%2C0%2C1%2C0%2C18.9%2C39c0-.12%2C0-.24%2C0-.36l63.86-31.19a49.55%2C49.55%2C0%2C0%2C0%2C61.69%2C0L524%2C582.44c0%2C.12%2C0%2C.24%2C0%2C.36A49.8%2C49.8%2C0%2C0%2C0%2C573.77%2C632.55Z%22%2F%3E%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%20%20%20%20%20%3C%2Fg%3E%20%3C%2Fsvg%3E")',
        backgroundSize: '40px auto',
        position: 'absolute',
        height: '70%',
        width: '85%',
        top: '30%',
        right: 0,
        zIndex: 0,
        left: 0,
      }}
      id="hero"
    >
      <Box flexGrow={1} px={2} alignSelf={{ base: 'flex-start', md: 'center' }} zIndex={10}>
        <Heading as="h1" size="xl" color="gray.600" mb={2}>
          Leszek Zając
        </Heading>

        <SubHeader borderBottom="none">Fizjoterapeuta, osteopata</SubHeader>

        <Box maxW="35rem" fontSize="14px" mb={8}>
          <Text as="cite" color="gray.500">
            "Osteopata stoi twardo na ziemi, obserwuje prawa i jako inżynier ludzkich ciał wprowadza
            je w życie w celu przywrócenia zdrowia."
          </Text>

          <Text color="gray.500" textAlign="right" mt="16px">
            ~ A. T. Still – twórca osteopatii
          </Text>
        </Box>

        <Link to="offer" containerId="main-section" smooth={true} duration={700}>
          <Button>Zobacz ofertę</Button>
        </Link>
      </Box>

      <Flex
        px={2}
        justifyContent="flex-end"
        minW={{ base: '200px', lg: '300px', xl: '450px' }}
        zIndex={10}
      >
        <Img fluid={heroImg.img.childImageSharp.fluid} style={{ width: '100%' }} />
      </Flex>
    </Box>
  );
};
