import React from 'react';
// @ts-ignore
import haldent from '../../images/haldent.jpeg';
import { Section } from '../common/Section/Section';
import { Cooperations } from './Cooperations/Cooperations';

export enum ActionType {
  EMAIL = 'EMAIL',
}

const coops = [
  {
    title: 'HALmed',
    subtitle: 'Zabierzów',
    image: haldent,
    details: [
      { id: 'address', value: 'ul. Rodziny Poganów 106A, 32-080 Zabierzów' },
      { id: 'open', value: 'Poniedziałek - Piątek 9:00 - 20:00, Sobota 9:00 - 16:00' },
      {
        id: 'email',
        value: 'biuro@halmed.pl',
        href: 'mailto:biuro@halmed.pl',
      },
      { id: 'phone', value: '12 889 99 05', href: 'tel:12 889 99 05' },
      {
        id: 'fb',
        value: 'HalmedZabierzow',
        href: 'https://www.facebook.com/HalmedZabierzow',
      },
      {
        id: 'ig',
        value: 'Halmed_Zabierzow',
        href: 'https://www.instagram.com/Halmed_Zabierzow/',
      },
    ],
  },
];

export const Offer = () => (
  <Section striped flexDirection="column" pt="16">
    <Cooperations cooperations={coops} />
  </Section>
);
