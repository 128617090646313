import { Flex, Image as ChakraImage, ImageProps } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent } from 'react';

export const Image: FunctionComponent<ImageProps> = (props) => (
  <ChakraImage
    alignSelf="center"
    objectFit="contain"
    boxSize={{ base: '128px', lg: '172px' }}
    rounded="full"
    p={2}
    boxShadow="0 2px 0 hsla(0, 0%, 100%, .5), inset 0 2px 2px hsla(0, 0%, 0%, 0.2)"
    border="1px solid"
    borderColor="gray.50"
    {...props}
  />
);
